import {
  BaseLayoutClient,
  BaseLayoutClientProps,
} from "@/layouts/base-layout/client";
import { Header } from "@/components/section/header";
import React from "react";
import { BaseLayoutProvider } from "@/layouts/base-layout/shared";
import { ContactSection } from "@/layouts/base-layout/navigation/contact-section";

export function BaseLayout(props: BaseLayoutClientProps) {
  return (
    <main className="flex h-screen max-h-screen min-h-screen flex-col overflow-hidden">
      <Header />
      <BaseLayoutProvider
        value={{
          serverNavigationItems: (
            <>
              <ContactSection />
            </>
          ),
        }}
      >
        <BaseLayoutClient className={props.className}>
          {props.children}
        </BaseLayoutClient>
      </BaseLayoutProvider>
    </main>
  );
}
